import { ClerkProvider } from "@clerk/nextjs";
import "../../styles/globals.css";
import type { AppProps } from "next/app";
// import localFont from "next/font/local";
import { ThemeProvider } from "next-themes";
import { GoogleAnalytics } from "nextjs-google-analytics";
import NextNProgress from "nextjs-progressbar";
import { ErrorBoundary } from "@sentry/nextjs";
import { Analytics } from "@vercel/analytics/react";
import { AnimatePresence } from "framer-motion";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

// TODO Telegraf
// See: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts#with-tailwind-css
// const TelegrafFont = localFont({
//   src: "./my-font.woff2",
//   variable: "--font-telegraf", // For tailwind CSS
// });

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ClerkProvider {...pageProps}>
          <AnimatePresence mode="wait" initial={false}>
            <ThemeProvider defaultTheme="dark" attribute="class" enableSystem>
              <GoogleAnalytics trackPageViews />
              <NextNProgress />

              <Component {...pageProps} />
              <Analytics />
            </ThemeProvider>
          </AnimatePresence>
        </ClerkProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
